import { Linkedin } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();
  return (
    <div
      className="dark:bg-indigo-800 py-24 bg-white flex justify-center"
      id="about"
    >
      <div className="container">
        <div className="w-full px-4">
          <div className="grid grid-cols-1">
            <div className="dark:text-white text-gray-800">
              <h2 className="text-4xl mb-6 font-bold">{t("About")}</h2>
              <p className="mb-4 text-xl">
                {t(
                  "My name is Narangarig Ganbold. Call me Garig!. I am currently working at a software development company. I've also been studying a lot on my own to learn new technologies and skills."
                )}
              </p>
              <p className="mb-8 text-xl">
                {t(
                  "I started as a Web developer in 2005. Nowadays I am more focusing on Blockchain development."
                )}
              </p>
              <div className="lg:flex items-center mb-6">
                <div className=" text-gray-700 dark:text-white rounded-xl bg-gray-100 dark:bg-indigo-700 p-6 text-center lg:mr-4 mb-6">
                  <div className="font-bold">{t("10 + Years")}</div>
                  <div className="text-sm text-gray-400">{t("Experience")}</div>
                </div>
                <div className="text-gray-700 dark:text-white rounded-xl bg-gray-100 dark:bg-indigo-700 p-6 text-center lg:mr-4 mb-6">
                  <div className="font-bold">{t("Worked on")}</div>
                  <div className="text-sm text-gray-400">
                    7 + {t("Companies")}
                  </div>
                </div>
                <div className="text-gray-700 dark:text-white rounded-xl bg-gray-100 dark:bg-indigo-700 p-6 text-center mb-6">
                  <div className="font-bold">300 +</div>
                  <div className="text-sm text-gray-400">{t("Projects")}</div>
                </div>
              </div>

              <a
                href="https://www.linkedin.com/in/narangarig/"
                target="_blank"
                className="inline-block rounded-xl bg-indigo-600 px-8 py-2 text-md lg:py-3 lg:text-lg text-white hover:bg-indigo-700 transition duration-500 font-bold"
                rel="noreferrer"
              >
                <div className="items-center flex">
                  <span>{t("Linked in")}</span>
                  <Linkedin className="ml-2 h-6 w-6" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
