import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationENG from "./locales/en/translation.json";
import translationMN from "./locales/mn/translation.json";

const resources = {
  eng: {
    translation: translationENG,
  },
  mn: {
    translation: translationMN,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    useLocalStorage: true,
    resources,
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
