const projects = [
  {
    name: "COVID-19 Reopening Directory",
    year: "2019 - 2020",
    desc: "Laravel, HTML5, CSS3, Bootstrap, React, Google API",
    url: "https://my.castlighthealth.com/covid-national-reopen-guidelines/",
  },
  {
    name: "COVID-19 Test Site Finder",
    year: "2019 - 2020",
    desc: "Laravel, HTML5, CSS3, Bootstrap, React, Google API",
    url: "https://my.castlighthealth.com/corona-virus-testing-sites/",
  },
  {
    name: "boxly.app",
    year: "2020 - 2022",
    desc: "React, Typescript, Tailwind CSS, Rest Api, Laravel",
    url: "https://www.boxly.app/",
  },
  {
    name: "cargospace.io",
    year: "2020 - 2022",
    desc: "React, Typescript, Tailwind CSS, Rest Api, Laravel",
    url: "https://www.cargospace.io/",
  },
  {
    name: "app.cargospace.io",
    year: "2020 - 2022",
    desc: "React, Typescript, Tailwind CSS, Rest Api, Laravel",
    url: "https://app.cargospace.io/login",
  },
  {
    name: "Bidmongol.com",
    year: "2020 - 2022",
    desc: "React, Typescript, Tailwind CSS, Rest Api",
    url: "https://app.bidmongol.com/",
  },
  {
    name: "Cryptonews.cool",
    year: "2021 - 2022",
    desc: "React, NextJS, Tailwind CSS, Rest Api",
    url: "https://www.cryptonews.cool/",
  },
  {
    name: "Anu.mn",
    year: "2021 - 2022",
    desc: "React, NextJS, Tailwind CSS, Sanity",
    url: "https://www.anu.mn/",
  },
  {
    name: "Cryptopuujin",
    year: "2021 - 2022",
    desc: "React, NextJS, Solidity, Tailwind CSS, Sanity CMS",
    url: "https://cryptopuujin.mn/",
  },
  {
    name: "Moonsharks NFT",
    year: "2021 - 2022",
    desc: "React, NextJS, Solidity, Tailwind CSS, MetaMask, EthersJS",
    url: "https://www.moonsharks.io/",
  },
  {
    name: "Poopsicle Airdrop",
    year: "2021 - 2022",
    desc: "React, NextJS, Solidity, Tailwind CSS, MetaMask, EthersJS",
    url: "https://shitdrop.poopsicle.wtf/",
  },
  {
    name: "Poopsicle Farming",
    year: "2021 - 2022",
    desc: "React, NextJS, Solidity, Tailwind CSS, MetaMask, EthersJS, Quicknode",
    url: "https://app.poopsicle.wtf/",
  },
  {
    name: "Poopsicle Crowdsale",
    year: "2021 - 2022",
    desc: "React, NextJS, Solidity, Tailwind CSS, MetaMask, EthersJS",
    url: "https://www.poopsicle.wtf/",
  },
  {
    name: "Poopsicle Token",
    year: "2021 - 2022",
    desc: "React, NextJS, Solidity, Tailwind CSS, MetaMask, EthersJS",
    url: "https://www.poopsicle.wtf/",
  },
  {
    name: "Crypto Mongolia",
    year: "2021 - 2022",
    desc: "React, NextJS, Tailwind CSS, Sanity",
    url: "https://www.crypto-mongolia.com/",
  },
  {
    name: "Mekei Foundation",
    year: "2019 - 2020",
    desc: "Gatsby JS, HTML5, CSS3, Bootstrap",
    url: "https://www.mekeifoundation.org/",
  },
  {
    name: "Himedia ",
    year: "2015 - 2019",
    desc: "Laravel, HTML5, CSS3, Bootstrap, React",
    url: "http://www.himedia.mn/",
  },
  {
    name: "Ubcar",
    year: "2010 - 2019",
    desc: "Laravel, HTML5, CSS3, Bootstrap, React",
    url: "http://www.ubcar.mn/",
  },
  {
    name: "Granite Towers ",
    year: "2018 - 2019",
    desc: "Wordpress, HTML5, CSS3, Bootstrap, Jquery",
    url: "https://www.granitetowers.us/",
  },
  {
    name: "The National Institute for Security Studies",
    year: "2018 - 2019",
    desc: "Laravel, HTML5, CSS3, Bootstrap, Jquery",
    url: "https://niss.gov.mn/",
  },
  {
    name: "Sugar",
    year: "2016 - 2018",
    desc: "Laravel, HTML5, CSS3, Bootstrap, Jquery",
    url: "https://www.sugar.mn/",
  },
  {
    name: "DDISH TV",
    year: "2014 - 2016",
    desc: "Laravel, HTML, CSS, Jquery",
    url: "https://www.ddishtv.mn/",
  },
  {
    name: "Gem International Corporation",
    year: "2014 - 2016",
    desc: "Laravel, HTML, CSS, Jquery",
    url: "http://gem.mn/",
  },
  {
    name: "SPS TV",
    year: "2012 - 2014",
    desc: "Laravel, HTML, CSS, Jquery",
    url: "https://sps.mn/",
  },
  {
    name: "GMobilenet",
    year: "2012 - 2014",
    desc: "Laravel, HTML, CSS, Jquery",
    url: "",
  },
  {
    name: "GMobile",
    year: "2012 - 2014",
    desc: "Symgony, HTML, CSS, Jquery",
    url: "https://gmobile.mn/",
  },
  {
    name: "Bayangol Hotel",
    year: "2010 - 2012",
    desc: "Laravel, HTML, CSS, Jquery",
    url: "https://bayangolhotel.mn/",
  },
  {
    name: "Erdenet Carpet",
    year: "2010 - 2012",
    desc: "Codeiginter, HTML, CSS, Jquery",
    url: "http://carpet.mn/",
  },
  {
    name: "Erdenet Cashmere",
    year: "2010 - 2012",
    desc: "Wordpress, HTML, CSS, Jquery",
    url: "https://eshop.nomin.mn/",
  },
  {
    name: "Flower Hotel",
    year: "2010 - 2012",
    desc: "Wordpress, HTML, CSS, Jquery",
    url: "https://www.flower-hotel.mn/",
  },
  {
    name: "Genco Tour",
    year: "2010 - 2012",
    desc: "Wordpress, HTML, CSS, Jquery",
    url: "http://www.genco-tour.mn/",
  },
  {
    name: "JTour Mongolia",
    year: "2010 - 2012",
    desc: "Wordpress, HTML, CSS, Jquery",
    url: "http://www.intertravel.mn/",
  },
  {
    name: "Lowe Finance (Finance and Insurance broking business)",
    year: "2010 - 2012",
    desc: "Wordpress, HTML, CSS, Jquery",
    url: "https://lowefinance.com.au/",
  },
  {
    name: "MAMA",
    year: "2010 - 2012",
    desc: "Wordpress, HTML, CSS, Jquery",
    url: "https://mama.mn/",
  },
  {
    name: "MEIC",
    year: "2010 - 2012",
    desc: "Wordpress, HTML, CSS, Jquery",
    url: "https://meic.mn/",
  },
  {
    name: "Tumen Khishigten Project",
    year: "2010 - 2012",
    desc: "PHP, Mysql, HTML, CSS, Jquery",
    url: "",
  },
  {
    name: "17 branch schools, individual web sites",
    year: "2005 - 2008",
    desc: "PHP, Mysql, HTML, CSS",
    url: "",
  },
  {
    name: "Online Learning System",
    year: "2005 - 2008",
    desc: "PHP, Mysql, HTML, CSS",
    url: "",
  },
  {
    name: "Mongolian University of Science and Technology",
    year: "2005 - 2008",
    desc: "PHP, Mysql, HTML, CSS, Adobe Flash",
    url: "https://must.edu.mn",
  },
];

export { projects };
