import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Layout({ children, title }) {
  return (
    <>
      <Helmet>
        <title>Narangarig Ganbold | {title}</title>
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
