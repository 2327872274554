import { useTranslation } from "react-i18next";

function Skills() {
  const { t } = useTranslation();
  return (
    <div
      className="dark:bg-gray-800 lg:py-28 py-24 bg-white flex justify-center"
      id="skills"
    >
      <div className="container">
        <div className="w-full px-4 dark:text-white text-gray-800">
          <h2 className="text-4xl mb-6 font-bold">{t("Skills")}</h2>
          <h2 className="text-2xl mb-6 font-bold">{t("Blockchain")}</h2>
          <div className="grid lg:grid-cols-6 grid-cols-2 gap-4 dark:text-white text-gray-700 mb-10">
            <div>
              <div className="flex items-center">
                <span>Solidity</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Truffle Framework</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Ganache</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Ethers JS</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Web3 JS</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>HardHat</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Remix Ethrereum</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Infura</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Alchemy</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Quicknode</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Moralis Web3</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Thirdweb</span>
              </div>
            </div>
          </div>
          <h2 className="text-2xl mb-10 font-bold">{t("Tools")}</h2>
          <div className="grid lg:grid-cols-6 grid-cols-2 gap-4 dark:text-white text-gray-700 mb-10">
            <div>
              <div className="flex items-center">
                <i className="devicon-photoshop-plain text-5xl mr-2"></i>
                <span>Photoshop</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-vscode-plain text-5xl mr-2"></i>
                <span>VS Code</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-atom-original text-5xl mr-2"></i>
                <span>Atom</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-jenkins-line text-5xl mr-2"></i>
                <span>Jenkins</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-slack-plain text-5xl mr-2"></i>
                <span>Slack</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-jira-plain text-5xl mr-2"></i>
                <span>Jira</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-github-plain text-5xl mr-2"></i>
                <span>Github</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-gitlab-plain text-5xl mr-2"></i>
                <span>Gitlab</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-chrome-plain text-5xl mr-2"></i>
                <span>Chrome</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-firefox-plain text-5xl mr-2"></i>
                <span>Firefox</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-googlecloud-plain text-5xl mr-2"></i>
                <span>Google Cloud</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-apple-original text-5xl mr-2"></i>
                <span>Apple</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-windows8-original text-5xl mr-2"></i>
                <span>Windows</span>
              </div>
            </div>
          </div>

          <h2 className="text-2xl mb-10 font-bold">{t("Front end")}</h2>
          <div className="grid lg:grid-cols-6 grid-cols-2 gap-4 dark:text-white text-gray-700 mb-10">
            <div>
              <div className="flex items-center">
                <i className="devicon-html5-plain text-5xl mr-2"></i>
                <span>HTML 5</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-css3-plain text-5xl mr-2"></i>
                <span>CSS 3</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-sass-plain text-5xl mr-2"></i>
                <span>Sass</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-less-plain-wordmark text-5xl mr-2"></i>
                <span>Less</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-javascript-plain text-5xl mr-2"></i>
                <span>Javascript</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-npm-original-wordmark text-5xl mr-2"></i>
                <span>NPM</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-babel-plain text-5xl mr-2"></i>
                <span>Babel</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-react-plain text-5xl mr-2"></i>
                <span>React JS</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-redux-plain text-5xl mr-2"></i>
                <span>Redux</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-vuejs-plain text-5xl mr-2"></i>
                <span>Vue JS</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-angularjs-plain text-5xl mr-2"></i>
                <span>Angular JS</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-nextjs-plain text-5xl mr-2"></i>
                <span>Next JS</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-gatsby-plain text-5xl mr-2"></i>
                <span>Gatsby JS</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-jquery-plain text-5xl mr-2"></i>
                <span>Jquery</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-bootstrap-plain text-5xl mr-2"></i>
                <span>Bootstrap</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-materialui-plain text-5xl mr-2"></i>
                <span>Material UI</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-tailwindcss-plain text-5xl mr-2"></i>
                <span>Tailwind CSS</span>
              </div>
            </div>
          </div>
          <h2 className="text-2xl mb-6 font-bold">{t("Back end")}</h2>
          <div className="grid lg:grid-cols-6 grid-cols-2 gap-4 dark:text-white text-gray-700 mb-10">
            <div>
              <div className="flex items-center">
                <i className="devicon-php-plain text-5xl mr-2"></i>
                <span>PHP</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-nodejs-plain text-5xl mr-2"></i>
                <span>Node JS</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-express-original text-5xl mr-2"></i>
                <span>Express JS</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-laravel-plain text-5xl mr-2"></i>
                <span>Laravel</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-symfony-original text-5xl mr-2"></i>
                <span>Symfony</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-codeigniter-plain text-5xl mr-2"></i>
                <span>Codeigniter</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-yii-plain text-5xl mr-2"></i>
                <span>Yii Framework</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-wordpress-plain text-5xl mr-2"></i>
                <span>Wordpress</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-mysql-plain text-5xl mr-2"></i>
                <span>Mysql</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-postgresql-plain text-5xl mr-2"></i>
                <span>Postgresql</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-mongodb-plain text-5xl mr-2"></i>
                <span>Mongo DB</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-firebase-plain text-5xl mr-2"></i>
                <span>Firebase</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-graphql-plain text-5xl mr-2"></i>
                <span>Graphql</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-apache-plain text-5xl mr-2"></i>
                <span>Apache</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-nginx-plain text-5xl mr-2"></i>
                <span>Nginx</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <i className="devicon-amazonwebservices-original text-5xl mr-2"></i>
                <span>Amazon Amplify</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Sanity CMS</span>
              </div>
            </div>
          </div>
          <h2 className="text-2xl mb-6 font-bold">{t("Mobile")}</h2>
          <div className="grid lg:grid-cols-6 grid-cols-2 gap-4 dark:text-white text-gray-700">
            <div>
              <div className="flex items-center">
                <span>React Native</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Capacitor JS</span>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <span>Flutter</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
