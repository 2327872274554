import React, { useEffect } from "react";
import { ArrowRight, Mouse } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { threejs } from "./Animation";

function Hero() {
  const { t } = useTranslation();
  useEffect(() => {
    threejs();
  }, []);
  return (
    <div
      className="lg:h-screen dark:bg-gray-900 bg-gray-100 flex justify-center relative overflow-hidden"
      id="home"
    >
      <div className="absolute bottom-12 dark:text-white text-gray-800 animate-bounce z-30">
        <Mouse className="h-8 w-8" />
      </div>
      <div
        className="absolute left-0 bg-transparent w-screen h-screen z-10 top-0"
        id="wrapper"
      ></div>
      <div className="container">
        <div className="w-full lg:h-screen py-48 px-4 flex items-center relative z-20">
          <div>
            <div className="dark:text-gray-300 text-gray-800 text-2xl mb-4">
              {t("Hello, I'm")}
            </div>
            <h1 className="dark:text-white text-gray-800 text-3xl lg:text-4xl mb-4 font-bold tracking-tighter uppercase">
              {t("Narangarig")} {t("Ganbold")}
            </h1>
            <div className="dark:text-gray-300 text-gray-800 text-2xl font-bold mb-9">
              {t("Full-Stack")} {` `}
              {/* {`&`} {t("Blockchain")} */}
              {t("developer")}.
            </div>
            <a
              href="/#contact"
              className="inline-block rounded-lg bg-indigo-600 px-8 py-2 text-lg lg:py-3 text-white hover:bg-indigo-800 transition duration-500 font-bold"
            >
              <div className="items-center flex">
                <span>{t("Contact")}</span>
                <ArrowRight className="ml-2" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
