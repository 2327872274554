import {
  Linkedin,
  Github,
  Instagram,
  Facebook,
  Twitter,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="bg-indigo-800 lg:py-28 py-24">
      <div className="lg:flex lg:justify-center text-center mb-6">
        <a
          href="/#home"
          className="text-white block text-md mr-1 py-2 px-5 hover:text-indigo-200"
        >
          {t("Home")}
        </a>
        <a
          href="/#about"
          className="text-white block text-md mr-1 py-2 px-5 hover:text-indigo-200"
        >
          {t("About")}
        </a>
        {/* <a
          href="/#video"
          className="text-white block text-md mr-1 py-2 px-5 hover:text-indigo-200"
        >
          {t("Video")}
        </a> */}
        <a
          href="/#projects"
          className="text-white block text-md mr-1 py-2 px-5 hover:text-indigo-200"
        >
          {t("Projects")}
        </a>
        <a
          href="/#skills"
          className="text-white block text-md mr-1 py-2 px-5 hover:text-indigo-200"
        >
          {t("Skills")}
        </a>
        <a
          href="/#contact"
          className="text-white block text-md  py-2 px-5 hover:text-indigo-200"
        >
          {t("Contact")}
        </a>
      </div>
      <div className="flex justify-center mb-6">
        <a
          href="https://www.linkedin.com/in/narangarig/"
          target="_blank"
          className="text-white mr-1 py-2 px-5 hover:text-indigo-200"
          rel="noreferrer"
        >
          <Linkedin className="h-6 w-6" />
        </a>
        <a
          href="https://github.com/Narangarig"
          target="_blank"
          className="text-white mr-1 py-2 px-5 hover:text-indigo-200"
          rel="noreferrer"
        >
          <Github className="h-6 w-6" />
        </a>
        <a
          href="https://www.instagram.com/narangarig/"
          target="_blank"
          className="text-white py-2 px-5 hover:text-indigo-200"
          rel="noreferrer"
        >
          <Instagram className="h-6 w-6" />
        </a>
        <a
          href="https://www.facebook.com/narangarig.g"
          target="_blank"
          className="text-white py-2 px-5 hover:text-indigo-200"
          rel="noreferrer"
        >
          <Facebook className="h-6 w-6" />
        </a>
        <a
          href="https://twitter.com/narangarig"
          target="_blank"
          className="text-white py-2 px-5 hover:text-indigo-200"
          rel="noreferrer"
        >
          <Twitter className="h-6 w-6" />
        </a>
      </div>
      <div className="text-white text-center text-sm opacity-60">
        ©{new Date().getFullYear()} {t("Narangarig")} {t("Ganbold")}.
      </div>
    </div>
  );
}

export default Footer;
