import React, { useState, useEffect } from "react";
import { Translate, SunFill, List, X, MoonFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import useDarkMode from "./hooks/useDarkMode";

function Navbar() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [colorTheme, setTheme] = useDarkMode();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setTheme("dark");
  }, [setTheme]);

  return (
    <div className="fixed w-full z-40">
      <div
        className={`absolute bg-white w-full px-8 py-8 z-10 lg:-top-64 opacity-0 transition-all ease-in-out duration-500 ${
          mobileMenu ? "top-16 opacity-100" : "-top-64 opacity-0"
        }`}
      >
        <a
          href="/#home"
          onClick={() => setMobileMenu(!mobileMenu)}
          className="block text-2xl mb-4 text-gray-800 hover:text-gray-600 transition duration-300"
        >
          {t("Home")}
        </a>
        <a
          href="/#about"
          onClick={() => setMobileMenu(!mobileMenu)}
          className="block text-2xl mb-4 text-gray-800 hover:text-gray-600 transition duration-300"
        >
          {t("About")}
        </a>
        {/* <a
          href="/#video"
          onClick={() => setMobileMenu(!mobileMenu)}
          className="block text-2xl mb-4 text-gray-800 hover:text-gray-600 transition duration-300"
        >
          {t("Video")}
        </a> */}
        <a
          href="/#projects"
          onClick={() => setMobileMenu(!mobileMenu)}
          className="block text-2xl mb-4 text-gray-800 hover:text-gray-600 transition duration-300"
        >
          {t("Projects")}
        </a>
        <a
          href="/#skills"
          onClick={() => setMobileMenu(!mobileMenu)}
          className="block text-2xl mb-4 text-gray-800 hover:text-gray-600 transition duration-300"
        >
          {t("Skills")}
        </a>
        <a
          href="/#contact"
          onClick={() => setMobileMenu(!mobileMenu)}
          className="block text-2xl text-gray-800 hover:text-gray-600 transition duration-300"
        >
          {t("Contact")}
        </a>
      </div>
      <div
        className={`bg-white dark:bg-gray-800 h-16 flex items-center lg:justify-center z-40 fixed w-full`}
      >
        <div className="container">
          <div className="flex items-center px-4">
            <a
              href="/#home"
              className="dark:text-white text-gray-800 text-xl font-bold tracking-tight"
            >
              {t("Narangarig")}
            </a>
            <div className="ml-auto hidden lg:flex">
              <a
                href="/#about"
                className="dark:text-white text-gray-800 text-sm mr-1 py-2 px-5 rounded-3xl transition duration-300 dark:hover:bg-gray-900 hover:bg-gray-200 font-semibold"
              >
                {t("About")}
              </a>
              {/* <a
                href="/#video"
                className="dark:text-white text-gray-800 text-sm mr-1 py-2 px-5 rounded-3xl transition duration-300 dark:hover:bg-gray-900 hover:bg-gray-200 font-semibold"
              >
                {t("Video")}
              </a> */}
              <a
                href="/#projects"
                className="dark:text-white text-gray-800 text-sm mr-1 py-2 px-5 rounded-3xl transition duration-300 dark:hover:bg-gray-900 hover:bg-gray-200 font-semibold"
              >
                {t("Projects")}
              </a>
              <a
                href="/#skills"
                className="dark:text-white text-gray-800 text-sm mr-1 py-2 px-5 rounded-3xl transition duration-300 dark:hover:bg-gray-900 hover:bg-gray-200 font-semibold"
              >
                {t("Skills")}
              </a>
              <a
                href="/#contact"
                className="dark:text-white text-gray-800 text-sm py-2 px-5 mr-4 rounded-3xl transition duration-300 dark:hover:bg-gray-900 hover:bg-gray-200 font-semibold"
              >
                {t("Contact")}
              </a>
            </div>
            <div className="flex ml-auto lg:ml-0">
              <div
                onClick={() =>
                  i18n.changeLanguage(i18n.language === "en" ? "mn" : "en")
                }
                className="dark:text-white text-gray-800 flex items-center cursor-pointer mr-6 dark:hover:text-gray-400 hover:text-gray-700 transition duration-300"
              >
                <Translate className="h-5 w-5 mr-1" />
                <span className="text-xs">
                  {i18n.language === "en" ? "MN" : "EN"}
                </span>
              </div>
              <div
                onClick={() => setTheme(colorTheme)}
                className="dark:text-white text-gray-800 flex items-center cursor-pointer mr-6 lg:mr-0 dark:hover:text-gray-400 hover:text-gray-700 transition duration-300"
              >
                {colorTheme === "dark" ? (
                  <MoonFill className="h-5 w-5" />
                ) : (
                  <SunFill className="h-5 w-5" />
                )}
              </div>
              <div
                onClick={() => setMobileMenu(!mobileMenu)}
                className="dark:text-white text-gray-800 flex items-center cursor-pointer dark:hover:text-gray-400 hover:text-gray-700 transition duration-300 lg:hidden"
              >
                {!mobileMenu ? (
                  <List className="h-7 w-7" />
                ) : (
                  <X className="h-7 w-7" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
