import Layout from "../../components/Layout";
import Hero from "../../components/Hero";
import About from "../../components/About";
import Projects from "../../components/Projects";
import Skills from "../../components/Skills";
import Contact from "../../components/Contact";
// import Video from "../../components/Video";

function Index() {
  return (
    <Layout title="Home">
      <Hero />
      <About />
      {/* <Video /> */}
      <Projects />
      <Skills />
      <Contact />
    </Layout>
  );
}

export default Index;
