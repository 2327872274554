import React, { useState } from "react";
import { ArrowDownShort, ArrowRightShort } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { projects } from "../data/projects";

function Projects() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const per_page = 12;
  const p = Math.floor(projects.length / per_page);
  const limit = projects.length % per_page === 0 ? p : p + 1;

  return (
    <div
      className="dark:bg-gray-900 lg:py-28 py-24 bg-gray-100 flex justify-center"
      id="projects"
    >
      <div className="container">
        <div className="w-full px-4 dark:text-white text-gray-800">
          <h2 className="text-4xl font-bold text-center mb-16">
            {t("Projects")}
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {projects.map((project, i) => {
              if (i < page * per_page)
                return (
                  <div key={i}>
                    <div className="rounded-lg dark:bg-gray-800 bg-gray-200 block p-10 lg:h-60 relative">
                      <div className="font-bold text-xl mb-2">
                        {project.name}
                      </div>
                      <div className="text-xs mb-4 opacity-75">
                        {project.year}
                      </div>
                      <div className="mb-4 text-sm">
                        <span className="font-bold">{t("Used")}:</span>{" "}
                        {project.desc}
                      </div>
                      {project.url !== "" && (
                        <a
                          href={project.url}
                          target="_blank"
                          className="absolute bottom-0 right-0 inline-block rounded-lg bg-indigo-600 px-6 py-2 text-sm text-white hover:bg-indigo-800 transition duration-500 font-bold"
                          rel="noreferrer"
                        >
                          <div className="items-center flex">
                            <span>{t("Visit")}</span>
                            <ArrowRightShort className="ml-1 h-6 w-6" />
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                );
              return null;
            })}
          </div>
          {page < limit && (
            <div className="flex justify-center mt-16">
              <div
                onClick={() => setPage(page + 1)}
                className="flex items-center text-indigo-500 hover:opacity-75 text-xl cursor-pointer"
              >
                {t("Load more")}
                <ArrowDownShort className="ml-1 h-6 w-6" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Projects;
