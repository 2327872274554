import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Linkedin,
  Github,
  Instagram,
  Facebook,
  Twitter,
  X,
} from "react-bootstrap-icons";

function Contact() {
  const { t } = useTranslation();

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);

  const [err, setError] = useState("");
  const [success, setSuccess] = useState("");

  async function formSubmit(e) {
    e.preventDefault();
    setSuccess("");
    setError("");
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (
      nameRef.current.value !== "" &&
      pattern.test(emailRef.current.value) &&
      subjectRef.current.value !== ""
    ) {
      try {
        await fetch("https://submit-form.com/NMAc3nE7", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: nameRef.current.value,
            email: emailRef.current.value,
            subject: subjectRef.current.value,
            message: messageRef.current.value,
          }),
        });
        nameRef.current.value = null;
        emailRef.current.value = null;
        subjectRef.current.value = null;
        messageRef.current.value = null;
      } catch {
        nameRef.current.value = null;
        emailRef.current.value = null;
        subjectRef.current.value = null;
        messageRef.current.value = null;
      }
      setSuccess(t("Thank you. Message was sent"));
    } else {
      setError(t("Please enter values for all of the required fields"));
    }
  }

  return (
    <div
      className="dark:bg-gray-700 lg:py-28 py-24 bg-gray-100 flex justify-center"
      id="contact"
    >
      <div className="container">
        <div className="w-full px-4 dark:text-white text-gray-800">
          <h2 className="text-4xl mb-6 font-bold">{t("Contact")}</h2>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-20">
            <div className="">
              <p className="mb-10 text-2xl">{t("Just say Hi")}.</p>
              <p className="mb-8">
                {t(
                  "I'm always open to discuss about your project and talk about new things"
                )}
                .
              </p>
              <div className="lg:flex lg:justify-between">
                <div className="mb-6 lg:mb-0">
                  <div className="mb-4 text-sm dark:text-gray-300">
                    {t("Mail me at")}
                  </div>
                  <a
                    href="mailto:narangarig@gmail.com"
                    className="hover:opacity-80"
                  >
                    narangarig@gmail.com
                  </a>
                </div>
                <div>
                  <div className="mb-4 text-sm dark:text-gray-300">
                    {t("Follow me")}
                  </div>
                  <div className="flex">
                    <a
                      href="https://www.linkedin.com/in/narangarig/"
                      target="_blank"
                      className="mr-4 hover:opacity-80"
                      rel="noreferrer"
                    >
                      <Linkedin className="h-6 w-6" />
                    </a>
                    <a
                      href="https://github.com/Narangarig"
                      target="_blank"
                      className="mr-4 hover:opacity-80"
                      rel="noreferrer"
                    >
                      <Github className="h-6 w-6" />
                    </a>
                    <a
                      href="https://www.instagram.com/narangarig/"
                      target="_blank"
                      className="mr-4 hover:opacity-80"
                      rel="noreferrer"
                    >
                      <Instagram className="h-6 w-6" />
                    </a>
                    <a
                      href="https://www.facebook.com/narangarig.g"
                      target="_blank"
                      className="mr-4 hover:opacity-80"
                      rel="noreferrer"
                    >
                      <Facebook className="h-6 w-6" />
                    </a>
                    <a
                      href="https://twitter.com/narangarig"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:opacity-80"
                    >
                      <Twitter className="h-6 w-6" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <form onSubmit={formSubmit}>
                {success !== "" && (
                  <div className="mb-4 border-green-500 border-solid border-2 py-2 px-3 w-full lg:w-1/2 rounded-lg text-green-400 text-sm flex justify-between">
                    <span>{success}</span>
                    <X
                      className="cursor-pointer h-5 w-5"
                      onClick={() => setSuccess("")}
                    />
                  </div>
                )}
                {err !== "" && (
                  <div className="mb-4 border-red-500 border-solid border-2 py-2 px-3 w-full lg:w-1/2 rounded-lg text-red-400 text-sm flex justify-between">
                    <span>{err}</span>
                    <X
                      className="cursor-pointer h-5 w-5"
                      onClick={() => setError("")}
                    />
                  </div>
                )}
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder={`${t("Name")}*`}
                    className="px-4 py-3 lg:w-1/2 w-full dark:bg-gray-600 outline-none rounded-lg dark:focus:bg-gray-500 border-solid border-2 dark:border-transparent"
                    ref={nameRef}
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder={`${t("Email")}*`}
                    ref={emailRef}
                    className="px-4 py-3 lg:w-1/2 w-full dark:bg-gray-600 outline-none rounded-lg dark:focus:bg-gray-500 border-solid border-2 dark:border-transparent"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder={`${t("Subject")}*`}
                    ref={subjectRef}
                    className="px-4 py-3 lg:w-1/2 w-full dark:bg-gray-600 outline-none rounded-lg dark:focus:bg-gray-500 border-solid border-2 dark:border-transparent"
                  />
                </div>
                <div className="mb-4">
                  <textarea
                    placeholder={`${t("Message")}`}
                    ref={messageRef}
                    className="px-4 py-3 lg:w-3/4 w-full dark:bg-gray-600 outline-none rounded-lg h-28 dark:focus:bg-gray-500 border-solid border-2 dark:border-transparent"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="inline-block rounded-xl bg-indigo-600 px-8 py-2 text-md lg:py-3 lg:text-lg text-white hover:bg-indigo-700 transition duration-500 font-bold"
                >
                  <div>
                    <span>{t("Submit")}</span>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
